import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';

import Header from '../components/header';
import MaxWidth from '../core/modules/max-width-content';
import RenderList from '../core/modules/render-list';
import { useTranslations } from '../translations/useTranslations';
import Footer from '../components/footer';
import useOrders, { getFetchLicensesEndpoint } from '../apis/licenses';
import LoadingContainer from '../core/modules/loading-container';
import searchQuery from '../core/utils/searchQuery';
import PageTitleSlots from '../core/modules/page-title-slots';

import { PageTitleWrapper } from '../components/licenses/licenses.view';

import { Button, SelectArea } from '../core/ui/fields';

// import { fetchApi } from '../../apis/utils/fetch';
import { fetchApi } from '../apis/utils/fetch';
import { constructFilterParameters, constructParamaters } from '../apis/utils/fetch-app-state';

const LicensesScreen = ({ history }) => {
  // License data
  const { data, isLoading, isError, postRequest, deleteRequest, count, goToPage, nextPage, previousPage, executeFilter } = useOrders();

  // translations
  const { getTranslation } = useTranslations();

  // State
  const [query, setQuery] = React.useState('');
  const [overlay, setOverlay] = React.useState(false);
  const { searchedData } = searchQuery(data.results || [], query);

  const [validfilterOptions, setValidFilterOptions] = React.useState([
    {
      field: 'valid_to',
      options: [
        {
          field: 'Default',
        },
        {
          field: 'Valid',
        },

        {
          field: 'Expired',
        },
      ],
    },
  ]);

  const [activeValidFilter, setActiveValidFilter] = React.useState(null); // Default to 'Valid'
  const [activeFilters, setActiveFilters] = React.useState([]);

  const formatAsDate = date => {
    return [date.getFullYear(), date.getMonth(), date.getDate()].join('-');
  };

  const getFirstDayOfMonth = (month = null) => {
    const date = new Date(),
      y = date.getFullYear(),
      m = date.getMonth();
    let _m = m + 1;

    if (month) _m = month;

    const firstDay = new Date(y, _m, 1);
    return formatAsDate(firstDay);
  };

  const getFirstDayOfYear = (year = null) => {
    const date = new Date(),
      y = date.getFullYear();
    let _y = y;

    if (year) _y = year;

    const firstDay = new Date(_y, 1, 1);
    return formatAsDate(firstDay);
  };

  const [periodFilterOptions, setPeriodFilterOptions] = React.useState([
    {
      label: 'Default',
    },

    {
      label: 'Previous Month',
      period: {
        start_date: getFirstDayOfMonth(new Date().getMonth()),
        end_date: getFirstDayOfMonth(new Date().getMonth() + 1),
      },
    },

    {
      label: 'This Month',
      period: {
        start_date: getFirstDayOfMonth(),
        end_date: getFirstDayOfMonth(new Date().getMonth() + 2),
      },
    },

    {
      label: 'Next Month',
      period: {
        start_date: getFirstDayOfMonth(new Date().getMonth() + 2),
        end_date: getFirstDayOfMonth(new Date().getMonth() + 3),
      },
    },

    {
      label: 'Previous Year',
      period: {
        start_date: getFirstDayOfYear(new Date().getFullYear() - 1),
        end_date: getFirstDayOfYear(new Date().getFullYear()),
      },
    },

    {
      label: 'This Year',
      period: {
        start_date: getFirstDayOfYear(),
        end_date: getFirstDayOfYear(new Date().getFullYear() + 1),
      },
    },
    {
      label: 'Next Year',
      period: {
        start_date: getFirstDayOfYear(new Date().getFullYear() + 1),
        end_date: getFirstDayOfYear(new Date().getFullYear() + 2),
      },
    },
  ]);

  const [activePeriodFilter, setActivePeriodFilter] = React.useState(periodFilterOptions[0]);

  const setActivePeriodHandler = filterLabel => {
    const dateType = 'Date';
    const _activeFilters = activeFilters.filter(filter => {
      if (filter?._type && filter?._type === dateType) {
        return filter?._type !== dateType;
      }

      return filter;
    });

    const filter = periodFilterOptions.find(f => f.label === filterLabel);
    setActivePeriodFilter(filter);

    let filterParams = [];
    if (filter.period) {
      filterParams = [
        {
          name: 'start_date',
          value: filter.period.start_date,
          _type: 'Date',
        },
        {
          name: 'end_date',
          value: filter.period.end_date,
          _type: 'Date',
        },
      ];
    }

    const _filters = [..._activeFilters, ...filterParams];

    setActiveFilters(_filters);
    executeFilter(_filters);
  };

  React.useEffect(() => {
    // Re-fetch licenses when activeFilter changes
    // You might already be doing this via useLicenses
  }, [activeValidFilter]);
  useEffect(() => {}, [validfilterOptions]);

  const setActiveValidFilterHandler = value => {
    // Probably dont need to use this..
    const updatedFilterOptions = validfilterOptions.map(fg => ({
      ...fg,
      options: fg.options.map(option => ({
        ...option,
        selected: option.field === value, // Select the new filter
      })),
    }));

    setValidFilterOptions(updatedFilterOptions);
    setActiveValidFilter(value); // Update active filter state

    let _filters = activeFilters.filter(f => f.name !== value.toLowerCase() && !['valid', 'expired', 'default'].includes(f.name));
    _filters = [
      ..._filters,
      {
        name: value.toLowerCase(),
        value: true,
      },
    ];

    setActiveFilters(_filters);

    executeFilter(_filters);
  };

  const exportCSV = () => {
    const params = constructFilterParameters(activeFilters);
    const endpoint = constructParamaters(`v1/licenses/export/`, params);

    fetchApi(endpoint).then(res => {
      res.blob().then(blob => {
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);

        link.download = `${new Date().toLocaleDateString()}_${activeValidFilter ?? 'all'}_export.csv`; // Replace with the file name and extension
        document.body.appendChild(link);
        link.click();
      });
    });
  };

  return (
    <>
      <Header />
      <MaxWidth>
        <PageTitleWrapper
          style={{
            marginTop: '50px',
          }}
          title={getTranslation('licenses.title')}
        >
          <SelectArea label={getTranslation('licenses.filterValidLabel')} defaultValue={activeValidFilter} onChange={e => setActiveValidFilterHandler(e.target.value)}>
            {validfilterOptions &&
              validfilterOptions.map((filterGroup, groupIndex) =>
                filterGroup.options.map((option, optionIndex) => (
                  <option key={`${groupIndex}-${optionIndex}`} value={option.field} selected={option.selected}>
                    {option.field}
                  </option>
                )),
              )}
          </SelectArea>

          <SelectArea label={getTranslation('licenses.filterPeriodLabel')} defaultValue={activePeriodFilter} onChange={e => setActivePeriodHandler(e.target.value)}>
            {periodFilterOptions &&
              periodFilterOptions.map((filter, index) => (
                <option key={`${filter.label}-${index}`} value={filter.label}>
                  {filter.label}
                </option>
              ))}
          </SelectArea>

          <Button secondary onClick={exportCSV} style={{ width: 'auto' }}>
            {getTranslation('list.export_csv')}
          </Button>
        </PageTitleWrapper>

        <LoadingContainer isLoading={isLoading} isError={isError}>
          <RenderList
            data={searchedData}
            fields={['product.name', 'created_at', 'ref_id', 'activation_count', 'device_count', 'valid_to']}
            widths={[12, 16, 30, 12, 12, 18]}
            handleClick={item => history.push(`/license/${item.id}`)}
            centerCols={['licenses']}
            hasOptions
            handleOptionsClick={(action, item) => {
              deleteRequest(item.id, item);
            }}
            pagination={{
              count: count,
              amountOfItems: data?.results?.length || 0,
              translations: {
                next: 'Next page',
                previous: 'Previous page',
                optionPrefix: 'Page ',
              },
              currentPage: 1,
              handleNext: () => {
                nextPage();
              },
              handlePrevious: () => {
                previousPage();
              },
              handleGoToPage: page => {
                goToPage(page, activeValidFilter ? activeValidFilter : '');
              },
              doNotRequestNewPageOnUpdate: true,
            }}
          />
        </LoadingContainer>

        <Footer />
      </MaxWidth>
    </>
  );
};

export default withRouter(LicensesScreen);
