import React from "react";
import styled from "styled-components";
import {H3} from "../../core/ui/typo";
import {Field} from "../../core/ui/fields";
import {useTranslations} from "../../translations/useTranslations";

// Styled components
const DetailsContainer = styled.div` > 
    div {
      padding: 12px 0;
    }
    
    .inputDetailWrapper {
        display: inline-block;
        height:60px;
        vertical-align: center;
        width: 45%;
        box-sizing: border-box;
        margin: 6px 0;
    }
  
    .p_r {
        padding-right: 10px;
    }
  
    .p_l {
        padding-left: 10px;
    }
  
    .inputDetailWrapperLabel {
        width: 10%;
    }
  
    ${
        props => props.secondary
        ? `
            background: rgba(0,0,0,0.06);
            padding: 22px;
            color: #353535;
            
            input {
                background: #fff;
                color: #353535;
                
                &::placeholder {
                    color: rgba(0,0,0,.5);
                }
            }
        `
        : ''
    }
`
const LicenseDetails = ({ licenseData = {}, secondary, handleChange }) => {
    const { getTranslation } = useTranslations();

    return <DetailsContainer secondary={secondary}>
        <div className='licenseDetails'>
            <H3> {getTranslation('license.licenseTitle')} </H3>
            <div className='inputDetailWrapper'>
                <Field
                    value={licenseData.ref_id}
                    onChange={e => handleChange('ref_id', e.target.value)}
                    placeholder={getTranslation('license.id')} />
            </div>
        </div>

        <div className='usageDetails'>
            <H3> {getTranslation('license.usageTitle')} </H3>
            <div className='inputDetailWrapper inputDetailWrapperLabel p_r'>
                <H3>Devices</H3>
            </div>
            <div className='inputDetailWrapper p_r p_l'>
                <Field
                    value={licenseData.device_count}
                    onChange={e => handleChange('device_count', e.target.value)} />
            </div>
            <div className='inputDetailWrapper p_l'>
                <Field
                    value={licenseData.device_limit}
                    onChange={e => handleChange('device_limit', e.target.value)} />
            </div>
        </div>
    </DetailsContainer>;
}

export default LicenseDetails