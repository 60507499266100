import React from 'react'
import styled from 'styled-components'

// Core
import {AccButton} from '../../core/ui/fields'

// Assets
import logo from '../../core/assets/logo.png'
import close from '../../core/assets/close.png'

// Panel styling
const PanelWrapper = styled.div`
  background: #c7a54a;
  color: #fff;
  width: 100%;
  box-shadow: 0px 22px 30px rgba(0,0,0,0.15);

  .panelHeading {
    position: relative;
    overflow: hidden;
    height: 115px;

    .panelHeadingBackground {
      width: 200%;
      height: 200px;
      background: #1f1c3c;
      transform: rotate(-5deg);
      position: absolute;
      top: -116px;
      left: -50%;
    }

    img.logo {
      position: absolute;
      top: 10px;
      left: 10px;
      height: 60px;
    }
  }

  .panelContent {
    padding: 22px;
  }

  .closePanelWrapper {
    position: absolute;
    top: 14px;
    right: 14px;
    cursor: pointer;

    img {
      float: right;
      width: 34px;
      height: auto;
      margin-left: 12px;
    }

    span {
      float: left;
      height: 30px;
      line-height: 35px;
    }
  }
`

// Panel component
const PanelModule = ({ children, handleClick }) => {
  return <PanelWrapper>
    <div className='panelHeading'>
      <div className='panelHeadingBackground' />
      <img className='logo' src={logo} alt='smart delta logo' />
      {
        handleClick && <div className='closePanelWrapper'>
          <AccButton onClick={handleClick}>
            <span> Close </span>
            <img src={close} alt='close overlay' />
          </AccButton>
        </div>
      }
    </div>
    <div className='panelContent'>
      {children}
    </div>
  </PanelWrapper>
}
export default PanelModule