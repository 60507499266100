import React from "react"
import {useTranslations} from "../../translations/useTranslations"
import styled from "styled-components"
import {H3} from "../../core/ui/typo"
import {Field} from "../../core/ui/fields"

const DetailsContainer = styled.div` >
div {
  padding: 12px 0;
}

  .inputDetailWrapper {
    display: inline-block;
    height:60px;
    vertical-align: center;
    width: 50%;
    box-sizing: border-box;
    margin: 6px 0;
  }

  .p_r {
    padding-right: 10px;
  }

  .p_l {
    padding-left: 10px;
  }

  .w_100 {
    width: 100%;
  }

  ${
          props => props.secondary
                  ? `
            background: rgba(0,0,0,0.06);
            padding: 22px;
            color: #353535;
            
            input {
                background: #fff;
                color: #353535;
                
                &::placeholder {
                    color: rgba(0,0,0,.5);
                }
            }
        `
                  : ''
  }
`

const DeviceLicenseDetails = ({
                                data = {},
                                secondary,
                                handleChange
                              }) => {
  const {getTranslation} = useTranslations()

  return <DetailsContainer secondary={secondary}>
    <div className='deviceDetails'>
      <H3> { getTranslation("devices.deviceLicenseDetails.title") } </H3>
      <div className='inputDetailWrapper w_100'>
        <Field
          value={data.device}
          onChange={e => handleChange('device', e.target.value)}
          placeholder={ getTranslation("devices.deviceLicenseDetails.device") }/>
      </div>
    </div>

    <div className='deviceLicenseInformation'>
      <H3> { getTranslation("devices.deviceLicenseDetails.information") } </H3>
      <div className='inputDetailWrapper w_100'>
        <Field
          value={data.ref_id}
          onChange={e => handleChange('ref_id', e.target.value)}
          placeholder={ getTranslation("devices.deviceLicenseDetails.ref_id") } />
      </div>
      <div className='inputDetailWrapper p_r'>
        <Field
          value={data.valid_from}
          onChange={e => handleChange('valid_from', e.target.value)}
          placeholder={ getTranslation("devices.deviceLicenseDetails.valid_from") }/>
      </div>
      <div className='inputDetailWrapper p_l'>
        <Field
          value={data.valid_to}
          onChange={e => handleChange('valid_to', e.target.value)}
          placeholder={ getTranslation("devices.deviceLicenseDetails.valid_to") }/>
      </div>
    </div>
  </DetailsContainer>
}

export default DeviceLicenseDetails