import React from 'react'

import {postApi} from './fetch'

// My context
const StateContext = React.createContext()

// Context reducer
function stateReducer(state, action) {
  switch (action.type) {
    case 'merge': {
      return {
        ...state,
        [action.key]: {
          ...state[action.key],
          ...action.payload
        },
      }
    }
    case 'reset': {
      return {}
    }
    case 'set': {
      return action.payload
    }
    default: {
      return state
    }
  }
}

// Use context hook
export const useAppState = () => {
  const context = React.useContext(StateContext)
  if (context === undefined) {
    throw new Error('useAppState must be used with-in the provider')
  }
  return context
}

// Provider
const AppStateProvider = ({ children, defaultState = {}, debugging, CSRFTokenEndpoint = '', CSRFTokenHeader = '' }) => {
  const [state, dispatch] = React.useReducer(stateReducer, defaultState)
  const [csrfToken, setCsrfToken] = React.useState('')

  // Expose a nice global way to track state
  React.useEffect(() => {
    if (debugging) {
      window._gww_app = {
        state
      }
    } else if (window._gww_app) {
      window._gww_app = null
    }
  }, [debugging])

  React.useEffect(() => {
    if (
      CSRFTokenEndpoint && CSRFTokenEndpoint.length > 2 &&
      CSRFTokenHeader && CSRFTokenHeader.length > 2
    ) {
      postApi(CSRFTokenEndpoint, {}).then(response => {
        if (response && response.token) {
          setCsrfToken(response.token)
        }
      })
    }
  }, [])

  // Methods for setting and reading state
  const setGlobalState = payload => dispatch({ type: 'set', payload })
  const setStateForEndpoint = (key, payload, init) => {
    if ((init && !state[key]) || !init) {
      dispatch({ type: 'merge', payload, key})
    }
  }

  const flushStateForEndpoint = (endpoint) => {
    setStateForEndpoint(endpoint, {
      error: '',
      isError: false,
      isLoading: false,
      data: {},
      cacheBusted: true,
      unAuthenticated: false,
      filterParams: null
    })
  }

  const getStateForEndpoint = key => {
    const stateByKey = state[key]

    if (!stateByKey && key) {
      return { isLoading: true, data: {}, found: false, cacheBusted: false, filterParams: null }
    }

    return stateByKey
  }

  // Methods for clearing cache and forcing a reset
  const forceResetEndpoints = endpoints => {
    if (typeof endpoints !== 'object') {
      console.error('App-state force reset requests an array to be passed, of endpoints to clear - E.G: [`orders`, `products`]')
    }

    if (endpoints && endpoints.length > 0) {
      endpoints.forEach(endpoint => {
        if (endpoint && state && state[endpoint]) {

          setStateForEndpoint(endpoint, {
            error: '',
            isError: false,
            isLoading: false,
            data: {},
            cacheBusted: true,
            unAuthenticated: false,
            filterParams: null
          })
        }
      })
    }
  }

  // Provider
  return (
    <StateContext.Provider
      value={{
        state,
        getStateForEndpoint,
        setStateForEndpoint,
        setGlobalState,
        CSRFTokenHeader,
        forceResetEndpoints,
        flushStateForEndpoint,
        csrfToken
      }}
    >
      {children}
    </StateContext.Provider>
  )
}

export default AppStateProvider
