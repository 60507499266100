import React from "react"
import useFetchAppState from "./utils/fetch-app-state"
import {useHistory} from "react-router-dom"

const useFetchDeviceLicense = (options = {}) => {
  const { licenseId } = options

  const [key] = React.useState(`devicelicense-${licenseId}`)
  const [endpoint] = React.useState(`v1/devicelicenses/?license=${licenseId}`)
  const payload = useFetchAppState(key, endpoint)

  const history = useHistory()

  if (payload.unAuthenticated) {
    history.push("/logout")
  }

  return payload
}

export default useFetchDeviceLicense
