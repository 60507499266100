import React from 'react'
import { NavLink } from 'react-router-dom'

// Core
import logo from '../../core/assets/logo.png'

// Views
import HeaderWrapper from './header.view'
import MaxWidth from '../../core/modules/max-width-content'

// Child component
const NavItem = ({ text, exact = true, to }) => {
  return <NavLink
    activeClassName='activeMenu'
    exact={exact}
    to={to}
  >
    {text}
  </NavLink>
}

// Component
const HeaderComponent = () => {
  return <HeaderWrapper>
    <MaxWidth>
      <div className='logoHolder'>
        <img src={logo} alt='Smart Delta Logo' />
      </div>
      <div className='navigationContainer'>
        <NavItem to='/orders' text='Orders' />
        <NavItem to='/products' text='Products' />
        <NavItem to='/admins' text='Admins' />
        <NavItem to='/reports' text='Reports' />
        <NavItem to='/licenses' text='Licenses' />
        <NavItem to='/logout' text='Log out' />
      </div>
    </MaxWidth>
  </HeaderWrapper>
}
export default HeaderComponent
