const translations = {
  'nl': {
    list: {
      empty: 'No records found...',
      confirm: 'Confirm you wish to delete this item',
      yes: 'Delete',
      no: 'Cancel',
      of: 'of',
      export_csv: 'Export CSV',
    },
    error: 'There was an error with your request, please try again.',
    resetpassword: {
      title: 'Recover your password',
      desc: 'Enter your e-mail and we will help recover your account for you.'
    },
    validate: {
      title: 'Change Password',
      desc: 'Processing password change request.',
      passwordNotSame: 'Passwords are not the same',
      tokenError: 'Password reset token was expired, please go back to recovery password and try again.',
      password: 'Enter new password',
      confirmPassword: 'Confirm password',
      submitError: 'Your password could not be reset, the server threw an error. Try again later or contact the administrator.'
    },
    login: {
      title: 'Login',
      desc: 'Enter your account credentials to continue.',
      username: 'Username',
      password: 'Password',
      button: 'LOGIN TO LICENSE PORTAL',
      error: 'You have not entered a valid username or password.',
      validAccount: 'Username or password did not match.',
      recoverEmail: 'Account Email',
      recover: 'Lost your password?',
      sentMessage: 'If an account has been found with this e-mail, you should soon get a password recovery email.'
    },
    orders: {
      title: 'Manage Orders',
      button: 'Add Order',
      searchPlaceholder: 'Search',
      searchByName: 'Name',
      submit: 'Register Order'
    },
    order: {
      id: 'Order ID',
      first_name: 'First name',
      last_name: 'Last name',
      name: 'Order Name',
      company: 'Company',
      phone: 'Phone number',
      email: 'Email',
      address: 'Address',
      zip: 'Postcode',
      state: 'State or Province',
      country: 'Country',
      orderTitle: 'Order Details:',
      contactTitle: 'Contact Information:',
      quantity: 'Quantity',
      manage: {
        title: (data) => `${data[0]}`,
        button: 'Save Order',
        back: 'Back to order list'
      },
      inlineError: 'Error trying to save order',
      licenses: 'Licenses:'
    },
    admins: {
      id: 'Account ID',
      addAdmin: 'Add User Account',
      editAdmin: 'Edit User Account',
      name: 'Username',
      password: 'Password',
      title: 'Manage Administrators',
      button: 'Add User',
      searchPlaceholder: 'Search through the admin accounts...'
    },
    products: {
      title: 'Manage Products',
      button: 'Add Products',
      searchPlaceholder: 'Search through the products...',
    },
    product: {
      submit: 'Add Product',
      name: 'Product Name',
      description: 'Product Description',
      edit: 'Change Product',
      public_cert: 'Public Key',
      no_cert: 'There is no public key coupled to this product',
      addProduct: 'Add Product',
      editProduct: 'Edit Product',
      inlineError: 'Error trying to save Product',
      manage: {
        title: (data) => `${data[0]}`,
        button: 'Save Product',
        back: 'Back to product list'
      },
      licensesList: 'Select a SKU',
      productsList: 'Select a Product'
    },
    user: {
      username: 'Username',
      email: 'Email address',
      password: 'Password',
      firstname: 'First Name',
      lastname: 'Last Name',
      submit: 'Add Admin Account',
      edit: 'Change Admin Account',
      inlineError: 'Error trying to save User'
    },
    chips: {
      placeholder: 'Define component(s) by using "," to seperate them...'
    },
    licenses: {
      title: 'Manage licenses',
      noLicenses: 'No licenses are attached to this order.',
      addLicense: 'Add License',
      modifyLicense: 'Modify License',
      deleteLicense: 'Delete License',
      filterPeriodLabel: 'Period',
      filterValidLabel: 'Expiration',
    },
    license: {
      licenseTitle: 'License details',
      usageTitle: 'Usage informations',
      id: 'License ID',
      title: 'Manage license',
      button: 'Save license',
      addLicense: 'Add SKU',
      submit: 'Submit SKU',
      inlineError: 'Error trying to add SKU',
      key: 'License Key',
      selectComponents: 'Select Component to attach license too:',
      duration: 'Duration - E.G: 2021',
      maxDevices: 'Max Devices',
      activationLimit: 'Activation Limit',
      deviceLimit: 'Device limit',
      deviceActivationLimit: 'Device Activiation limit',
      product: 'Select a Product',
      order: 'Order',
      error: 'Could not add the license, make sure all fields are filled in and try again.',
      validTo: 'Valid to',
      deviceId: 'Device ID',
    },
    devices: {
      devices: 'Devices',
      button: 'Add device',
      submit: 'Register device',
      deviceLicenseDetails: {
        title: 'Device:',
        information: 'Information:',
        ref_id: 'Reference',
        license: 'License',
        device: 'Device ID',
        valid_from: 'Activation date',
        valid_to: 'Expiration date',
        created_at: 'Creation date'
      }
    },
    devices: {
      devices: 'Devices',
      button: 'Add device',
      submit: 'Register device',
      deviceLicenseDetails: {
        title: 'Device:',
        information: 'Information:',
        ref_id: 'Reference',
        license: 'License',
        device: 'Device ID',
        valid_from: 'Activation date',
        valid_to: 'Expiration date',
        created_at: 'Creation date'
      }
    },
    renderlist_header: {
      ref_id: 'Reference',
      company: 'Company',
      first_name: 'First Name',
      last_name: 'Last Name',
      licenses: 'Licenses',
      id: 'ID',
      name: 'name',
      skus: 'skus',
      duration: 'Duration',
      lease_duration: 'Lease Duration',
      username: 'Username',
      device: {
        hwid: 'Device identifier',
      },
      product: {
        name: 'Product Name',
      },
      license: {
        duration: 'Duration',
        lease_duration: 'Lease Duration',
      },
      created_at: 'Created at',
      activation_count: 'Activations',
      device_count: 'Devices',
      valid_to: 'Valid to',
    },
    activation: {
      key: 'Activation Key',
      expiration_date: 'Experation Date',
      activation_date: 'Activation Date',
      hadrwareid: 'Hardware ID',
      button: 'Activate',
      error: 'Could not activate one or more licenses'
    },
    sku: {
      add: 'Add sku',
      edit: 'Edit sku',
      search: 'Search SKUs',
      price: 'Price',
      name: 'Name',
      ref_id: 'Reference ID',
      currency: 'Currency',
      description: 'Description',
      duration: 'Duration',
      lease_duration: 'Lease Duration',
      activeDevices: 'Device Count',
      activePerAll: 'Total Activations',
      activePerDevice: 'Activations per device',
    },
    reports: {
      title: 'Reports',
    }
  }
}

export default translations
