import React from 'react'
import styled from 'styled-components'

import { withRouter } from 'react-router-dom'

// Users Specific
import useUsers from '../apis/users'

// Generic components
import Header from '../components/header'
import MaxWidth from '../core/modules/max-width-content'
import RenderList from '../core/modules/render-list'
import PageTitle from '../core/modules/page-title'
import OverlayPanel from '../core/modules/overlay-panel'
import SearchModule from '../core/modules/search'
import searchQuery from '../core/utils/searchQuery'
import { useTranslations } from '../translations/useTranslations'
import Footer from '../components/footer'
import LoadingContainer from '../core/modules/loading-container'
import { H3 } from '../core/ui/typo'
import { FieldWithLabel, Button } from '../core/ui/fields'

// Add user panel
const AddAdminContainer = styled.div`
  padding: 0 0 12px 0;

  h3 {
    padding-bottom: 12px;
    border-bottom: 1px solid rgba(255,255,255,0.2);
    margin-bottom: 12px;
    font-size: 22px;
  }

  .inputDetailWrapper {
    display: inline-block;
    height: 95px;
    vertical-align: top;
    width: 50%;
    box-sizing: border-box;
    margin: 6px 0;

    &:nth-child(3), &:nth-child(5) {
      padding-right: 10px;
    }

    &:nth-child(4), &:nth-child(6) {
      padding-left: 10px;
    }

    &.full {
      width: 100%;
      padding: 0 0;
    }
  }
`

const ErrorWrapper = styled.div`
  .error {
    padding: 12px;
    background: red;
    color: #fff;
    margin-top: 12px;
  }
`

const defaultUser = {
  username: '',
  password: '',
  first_name: '',
  last_name: ''
}

// Reusable UI
const AdminsOverlay = ({
  data,
  isNewUser,
  handleAdd,
  handleSave,
  inlineError,
  handleUpdate
}) => {
  // translations.
  const { getTranslation } = useTranslations()

  // Mappings.
  const user = data
  const handleClick = isNewUser ? handleAdd : handleSave
  const buttonText = isNewUser ? getTranslation('user.submit') : getTranslation('user.edit')
  const overlayTitle = isNewUser ? getTranslation('admins.addAdmin') : getTranslation('admins.editAdmin')

  // Return generic view.
  return <ErrorWrapper>
    <AddAdminContainer>
      <H3> { overlayTitle } </H3>
      <div className='inputDetailWrapper full'>
        <FieldWithLabel
          id='account-username'
          value={user.username}
          label={getTranslation('user.username')}
          handleChange={e => handleUpdate('username', e.target.value)}
          placeholder={getTranslation('user.username')} />
      </div>
      <div className='inputDetailWrapper full'>
        <FieldWithLabel
          id='account-email'
          value={user.email}
          label={getTranslation('user.email')}
          handleChange={e => handleUpdate('email', e.target.value)}
          placeholder={getTranslation('user.email')} />
      </div>
      <div className='inputDetailWrapper full'>
        <FieldWithLabel
          id='account-password'
          isDisabled={!isNewUser}
          label={getTranslation('user.password')}
          value={isNewUser ? user.password : '************'}
          handleChange={e => handleUpdate('password', e.target.value)}
          placeholder={getTranslation('user.password')} />
      </div>
      <div className='inputDetailWrapper'>
        <FieldWithLabel
          value={user.first_name}
          id='account-firstname'
          label={getTranslation('user.firstname')}
          handleChange={e => handleUpdate('first_name', e.target.value)}
          placeholder={getTranslation('user.firstname')} />
      </div>
      <div className='inputDetailWrapper'>
        <FieldWithLabel
          value={user.last_name}
          id='account-lastname'
          label={getTranslation('user.lastname')}
          handleChange={e => handleUpdate('last_name', e.target.value)}
          placeholder={getTranslation('user.lastname')} />
      </div>
    </AddAdminContainer>
    <Button onClick={() => handleClick()}>
      {buttonText}
    </Button>
    {
      inlineError && <p className='error'>
        {getTranslation('user.inlineError')}
      </p>
    }
  </ErrorWrapper>
}

// Screen component
const AdminScreens = () => {
  const {
    data,
    isLoading,
    isError,
    postRequest,
    putRequest,
    deleteRequest,
    count,
    goToPage,
    nextPage,
    previousPage
  } = useUsers()
  const [user, setUser] = React.useState(defaultUser)

  // translations
  const { getTranslation } = useTranslations()

  // State
  const [query, setQuery] = React.useState('')
  const [overlay, setOverlay] = React.useState(false)
  const {searchedData} = searchQuery(data.results, query)
  const [inlineError, setInlineError] = React.useState(false)
  const [isNewUser, setisNewUser] = React.useState(false)

  // Handle add user
  const handleAddUser = () => {
    postRequest({
      ...user,
      uuid: Date.now()
    }).then((payload) => {
      if (!payload) {
        setInlineError(true)
      } else {
        setOverlay(false)
        setUser({})
        setInlineError(false)
      }
    })
  }

  const handleUpdate = (key, value) => {
    const newState = {
      ...user,
      [key]: value
    }

    setUser(newState)
  }

  const handleSaveUser = () => {
    putRequest(user.id, user).then((data) => {
      setOverlay(false)
      setUser({})
      setInlineError(false)
    }).catch(() => {
      setInlineError(true)
    })
  }

  React.useEffect(() => {
    if (!overlay) {
      setUser(defaultUser)
      setInlineError(false)
    }
  }, [overlay])

  // Render
  return <>
    <Header />
    <MaxWidth>
      <PageTitle
        title={getTranslation('admins.title')}
        buttonText={getTranslation('admins.button')}
        handleClick={() => {
          setUser(defaultUser)
          setisNewUser(true)
          setOverlay(true)
        }}
      />
      <SearchModule
        value={query}
        onChange={(v) => setQuery(v)}
        placeholder={getTranslation('admins.searchPlaceholder')}
      />
      <LoadingContainer isLoading={isLoading} isError={isError}>
        <RenderList
          data={searchedData}
          fields={['id', 'username']}
          widths={[ 10, 90 ]}
          handleClick={(user) => {}}
          hasDelete
          hasEdit
          hasNoRecover
          handleOptionsClick={(action, item) => {
            if (action === 'delete') {
              deleteRequest(item.id, item)
            }

            if (action === 'edit') {
              setUser(user)
              setisNewUser(false)
              setOverlay(true)
            }
          }}
          pagination={{
            'count': count,
            'amountOfItems': (data?.results?.length || 0),
            'translations': {
              next: 'Next page',
              previous: 'Previous page',
              optionPrefix: 'Page '
            },
            'currentPage': 1,
            'handleNext': () => {
              nextPage()
            },
            'handlePrevious': () => {
              previousPage()
            },
            'handleGoToPage': (page) => {
              goToPage(page)
            },
            'doNotRequestNewPageOnUpdate': true
          }}
        />
      </LoadingContainer>

      <OverlayPanel
        contentWidth={650}
        isOpen={overlay}
        handleClick={() => setOverlay(false)}
      >
        <AdminsOverlay
          data={user}
          handleUpdate={handleUpdate}
          handleAdd={handleAddUser}
          handleSave={handleSaveUser}
          inlineError={inlineError}
          isNewUser={isNewUser} />
      </OverlayPanel>

      <Footer />
    </MaxWidth>
  </>
}

export default withRouter(AdminScreens)
