import React from 'react'
import styled from 'styled-components'

import { withRouter } from 'react-router-dom'

// Product Specific
import useProducts from '../apis/products'

// Generic components
import Header from '../components/header'
import MaxWidth from '../core/modules/max-width-content'
import RenderList from '../core/modules/render-list'
import PageTitle from '../core/modules/page-title'
import OverlayPanel from '../core/modules/overlay-panel'
import SearchModule from '../core/modules/search'
import searchQuery from '../core/utils/searchQuery'
import { useTranslations } from '../translations/useTranslations'
import Footer from '../components/footer'
import LoadingContainer from '../core/modules/loading-container'
import { H3 } from '../core/ui/typo'
import { FieldWithLabel, Button } from '../core/ui/fields'

// Add product panel
const AddProductContainer = styled.div`
  padding: 0 0 12px 0;

  h3 {
    padding-bottom: 12px;
    border-bottom: 1px solid rgba(255,255,255,0.2);
    margin-bottom: 12px;
    font-size: 22px;
  }

  .inputDetailWrapper {
    display: inline-block;
    height: 95px;
    vertical-align: top;
    width: 100%;
    box-sizing: border-box;
    margin: 6px 0;

    &:nth-child(2), &:nth-child(4) {
      padding-right: 10px;
    }

    &:nth-child(3), &:nth-child(5) {
      padding-left: 10px;
    }
  }
`

const ErrorWrapper = styled.div`
  .error {
    padding: 12px;
    background: red;
    color: #fff;
    margin-top: 12px;
  }
`

const defaultProduct = {
  name: '',
  uuid: '',
  skus: []
}

// Reusable UI
const ProductOverlay = ({
  data,
  isNewProduct,
  handleAdd,
  inlineError,
  handleUpdate,
}) => {
  // translations.
  const { getTranslation } = useTranslations()


  // Mappings.
  const product = data
  const buttonText = isNewProduct ? getTranslation('product.submit') : getTranslation('product.edit')
  const overlayText = isNewProduct ? getTranslation('product.addProduct') : getTranslation('product.editProduct')

  // Return generic view.
  return <ErrorWrapper>
    <AddProductContainer>
      <H3> { overlayText } </H3>
      <div className='inputDetailWrapper'>
        <FieldWithLabel
          value={product.name}
          id='product-name'
          label={getTranslation('product.name')}
          handleChange={e => handleUpdate('name', e.target.value)}
          placeholder={getTranslation('product.name')} />
      </div>
      {
        isNewProduct
      }
    </AddProductContainer>
    <Button onClick={() => handleAdd()}>
      {buttonText}
    </Button>
    {
      inlineError && <p className='error'>
        {getTranslation('product.inlineError')}
      </p>
    }
  </ErrorWrapper>
}

// Screen component
const ProductsScreen = ({ history }) => {
  const {
    data,
    isLoading,
    isError,
    postRequest,
    fetchRequest,
    deleteRequest,
    count,
    goToPage,
    nextPage,
    previousPage
  } = useProducts()

  const [product, setProduct] = React.useState(defaultProduct)
  const [isNewProduct, setisNewProduct] = React.useState(false)

  // State
  const [query, setQuery] = React.useState('')
  const [overlay, setOverlay] = React.useState(false)

  const {searchedData} = searchQuery(data.results, query)
  const [inlineError, setInlineError] = React.useState(false)

  // translations
  const { getTranslation } = useTranslations()

  // Handle add product
  const handleAddProduct = () => {
    if (product.name) {
      postRequest({
        ...product,
        uuid: Date.now()
      }).then((payload) => {
        if (!payload) {
          setInlineError(true)
        } else {
          setProduct(defaultProduct)
          setInlineError(false)
          history.push(`/product/${payload.id}`)

          setTimeout(() => {
            fetchRequest()
          }, 1200)
        }
      })
    } else {
      setInlineError(true)
    }
  }

  const updateProduct = (key, value) => {
    const newState = {
      ...product,
      [key]: value
    }

    setProduct(newState)
  }

  React.useEffect(() => {
    if (!overlay) {
      setisNewProduct(false)
      setProduct(defaultProduct)
      setInlineError(false)
    }
  }, [overlay])

  // Render
  return <>
    <Header />
    <MaxWidth>
      <PageTitle
        title={getTranslation('products.title')}
        buttonText={getTranslation('products.button')}
        handleClick={() => {
          setOverlay(true)
          setProduct(defaultProduct)
          setisNewProduct(true)
        }}
      />
      <SearchModule
        value={query}
        onChange={(v) => setQuery(v)}
        placeholder={getTranslation('products.searchPlaceholder')}
      />

      <LoadingContainer isLoading={isLoading} isError={isError}>
        {<RenderList
          data={searchedData}
          fields={['name']}
          widths={[ 10, 46, 32, 12 ]}
          handleClick={(item) => history.push(`/product/${item.id}`)}
          centerCols={['licenses']}
          hasDelete
          handleOptionsClick={(action, item) => {
            if (action === 'delete') {
              deleteRequest(item.id, item)
            }

            if (action === 'edit') {
              setProduct(item)
              setOverlay(true)
            }
          }}
          pagination={{
            'count': count,
            'amountOfItems': (data?.results?.length || 0),
            'translations': {
              next: 'Next page',
              previous: 'Previous page',
              optionPrefix: 'Page '
            },
            'currentPage': 1,
            'handleNext': () => {
              nextPage()
            },
            'handlePrevious': () => {
              previousPage()
            },
            'handleGoToPage': (page) => {
              goToPage(page)
            },
            'doNotRequestNewPageOnUpdate': true
          }}
        />}
      </LoadingContainer>

      <OverlayPanel
        contentWidth={650}
        isOpen={overlay}
        handleClick={() => setOverlay(false)}
      >
        <ProductOverlay
          data={product}
          handleUpdate={updateProduct}
          handleAdd={handleAddProduct}
          inlineError={inlineError}
          isNewProduct={isNewProduct} />
      </OverlayPanel>

      <Footer />
    </MaxWidth>
  </>
}

export default withRouter(ProductsScreen)
