import React from 'react'
import styled from 'styled-components'

import {useTranslations} from '../../translations/useTranslations'
import deleteIcon from '../assets/delete.png'
import undeleteIcon from '../assets/undelete.png'
import editIcon from '../assets/edit.png'
import copyIcon from '../assets/copy.png'
import { H3 } from '../ui/typo'
import { Button } from '../ui/fields'
import OverlayPanel from './overlay-panel'
import Pagination, { useDetermineAmountofPages } from './pagination'

const datetime_regex = '^[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}:[0-9]{2}'

// Wraps the whole module and global styling for the module
const ListModuleWrapper = styled.div`
  white-space: nowrap;
  padding-bottom: 60px;

  .listItemContainer {
    white-space: nowrap;
  }
`

// Styling specific to the header
const ListHeader = styled.div`
  opacity: 0.6;
  white-space: nowrap;
  text-transform: uppercase;
  font-family: 'Montserrat', sans-serif;
  font-weight: 800;
  font-size: 16px !important;

  > div {
    white-space: nowrap;

    width: 100%;
    &.hasOptions {
      width: calc(100% - 110px);
      overflow: hidden;

      &.hasActivate {
        width: calc(100% - 150px);
      }
    }

    &.isDisabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }
`

// Styling for the list item
const ListItem = styled.div`
  display: block;
  white-space: nowrap;
  border-bottom: 1px solid rgba(0,0,0,0.1);
  cursor: pointer;

  &:hover {
    background: rgba(0,0,0,0.05);
  }

  .container {
    display: inline-block;
    vertical-align: top;

    width: 100%;

    &.hasOptions {
      width: calc(100% - 110px);
      overflow: hidden;
      white-space: no-wrap;
      text-overflow: ellipsis;

      &.hasActivate {
        width: calc(100% - 150px);
      }
    }
  }

  .options {
    display: inline-block;
    vertical-align: top;
    width: 50px;
    box-sizing: border-box;
    padding: 5px;
    cursor: pointer;

    img {
      width: 15px;
    }

    .option {
      display: inline-block;
      padding: 8px;
      border-radius: 6px;
      position: relative;
      top: 6px;

      &:hover {
        background: rgba(0,0,0,0.1);
      }
    }
  }

  .hasActivate {
    width: 150px;
  }

  .activate {
    width: 110px;
    padding: 8px 18px;
    border-radius: 3px;
    background: rgba(0,0,0,0.7);
    color: #fff;
    margin: 6px 10px;
    text-align: center;

    &:hover {
      background: rgba(0,0,0,0.85);
    }
  }
`

const ListCol = styled.div`
  display: inline-block;
  vertical-align: top;
  height: 60px;
  line-height: 60px;
  padding: 0px 8px;
  box-sizing: border-box;
  white-space: no-wrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: ${props => props.passedWidth}%;
  text-align: ${props => props.isCenter ? 'center' : 'left'} ;
`

// Empty state
const EmptyList = styled.div`
  padding: 20px;
  background: rgba(0,0,0,0.05);
  text-align: center;
`

const datetime_formater = new Intl.DateTimeFormat("default", {
  year: "numeric", month: "long", day: "2-digit",
  hour: 'numeric', minute: 'numeric', second: 'numeric'
})

// helper function for building item
const BuildListItem = ({
  field,
  isCenter,
  defaultFieldWidth,
  customWidth,
  limit,
  keyToUse,
  isHeader
}) => {
  const { getTranslation } = useTranslations()
  const processValue = value => {
    if (value === null) {
      return ''
    }

    if (typeof value === 'object') {
      return value.length
    }

    if (typeof value === 'string' && value.match(datetime_regex)) {
      // the string is a datetime value.
      const date = new Date(value)
      if (date.toString() !== 'Invalid Date') {
        return datetime_formater.format(date)
      }
    }

    if (isHeader) {
      return getTranslation(`renderlist_header.${value}`)
    }

    return value
  }
  const processLimit = limit => {
    if (limit === 0) {
      return `${getTranslation('list.of')} ∞`
    } else if (limit) {
      return `${getTranslation('list.of')} ${limit}`
    } else {
      return
    }
  }
  const value = field === 'order_identifier' ? 'id' : field
  return <ListCol
    key={keyToUse}
    isCenter={isCenter}
    passedWidth={ customWidth || defaultFieldWidth }
  >
    {processValue(value)} {processLimit(limit)}
  </ListCol>
}

// util
const buildClassName = (hasDelete, hasActivate, isDisabled) => {
  const optionClass = hasDelete ? 'hasOptions' : ''
  const activateClass = hasActivate ? 'hasActivate' : ''
  const disabledClass = isDisabled ? 'isDisabled' : ''

  return `${optionClass} ${activateClass} ${disabledClass}`
}

// Generic module for rendering a dynamic data in a generic way.
const RenderListOfData = ({
  data,
  fields = [],
  centerCols = [],
  widths,
  handleClick,
  emptyText = '',
  hasDelete,
  hasEdit,
  hasCopy,
  handleOptionsClick,
  handleActivate,
  hasNoRecover,
  pagination
}) => {
  const { getTranslation } = useTranslations()
  const [showConfirm, setShowConfirm] = React.useState(false)
  const [handleItem, setHandleItem] = React.useState(null)

  const { count, amountOfItems } = pagination || {}
  const numberOfPages = useDetermineAmountofPages(count, amountOfItems)

  // Defensive coding: Fields are used to render the cols.
  if (!fields || fields.length === 0) {
    console.error('Render-List requires fields to be defined: [`field`, `field2`]')
  }

  // if no widths are passed, calc a default
  const defaultFieldWidth = 100 / fields.length
  const hasWidths = widths && widths.length === fields.length

  // Render the module.
  return <ListModuleWrapper>
    <ListHeader>
      <div className={buildClassName(hasDelete, handleActivate)}>
        {
          fields.map((field, i) => BuildListItem({
            field,
            defaultFieldWidth,
            keyToUse: `list-item-header-${i}-${field}` ,
            isCenter: centerCols && centerCols.indexOf(field) >= 0,
            customWidth: hasWidths ? widths[i] : false,
            isHeader: true
          }))
        }
      </div>
    </ListHeader>
    <div className='listItemContainer'>
      {
        (!data || data.length === 0) && <EmptyList>
          {emptyText || getTranslation('list.empty')}
        </EmptyList>
      }
      {
        data && data.length > 0 && data.map((item, i) => <ListItem key={`list-item-${i}`}>
          <div
            className={`container ${buildClassName(hasDelete, handleActivate, item.deleted_at !== null)}`}
            onClick={() => handleClick(item)}
          >
            {
              fields.map((field, ix) => BuildListItem({
                field: field.split('.').reduce((acc, part) => acc && acc[part], item),
                defaultFieldWidth,
                keyToUse: `list-item-col-${ix}-${field}` ,
                isCenter: centerCols && centerCols.indexOf(field) >= 0,
                customWidth: hasWidths ? widths[ix] : false,
                limit: field==='activation_count' ? item.activation_limit : field==='device_count' ? item.device_limit : null,
                handleClick
              }))
            }
          </div>
          
          {
            (hasDelete || hasCopy || hasEdit) && handleOptionsClick
            && <div
              className='options'
            >
            {
              hasCopy && !item.deleted_at && <div
                className='option'
                onClick={() => {
                  handleOptionsClick('copy', item)
                }}
              >
                <img
                  src={copyIcon} title='copy' alt='copy'
                />
              </div>
            }
                
            {
              hasEdit && !item.deleted_at && <div
                className='option'
                onClick={() => {
                  handleOptionsClick('edit', item)
                }}
              >
                <img
                  src={editIcon} title='edit' alt='edit'
                />
              </div>
            }
              
            {
              (item.deleted_at === null || hasNoRecover) && <div
                className='option'
                onClick={() => {
                  setShowConfirm(true)
                  setHandleItem(item)
                }}
              >
              <img
                src={deleteIcon} title='delete item' alt='delete item'
              />
              </div>
            }

            {
              (!hasNoRecover && item.deleted_at !== null) && <div
                className='option'
                onClick={() => {
                  handleOptionsClick('undelete', item)
                }}
              >
                <img
                  src={undeleteIcon} title='restore item' alt='restore item'
                />
              </div>
            }

            </div>
          }

          {
            hasDelete && handleActivate && !item.enabled && <div className='options hasActivate'>
              <div className='activate' onClick={() => {handleActivate(item)}}>
                Activate
              </div>
            </div>
          }
        </ListItem>)
      }
      {(pagination !== null || pagination !== undefined) && <Pagination
        {...pagination}
        numberOfPages={parseInt(numberOfPages)}
      />}
    </div>
    {
      <OverlayPanel
        isOpen={showConfirm}
        handleClick={() => setShowConfirm(false)}
      >
        <H3 style={{ margin: '0 0 22px 0', textAlign: 'center' }}>
          {getTranslation('list.confirm')}
        </H3>
        <Button onClick={() => {
          handleOptionsClick('delete', handleItem)
          setHandleItem(null)
          setShowConfirm(false)
        }}>
          {getTranslation('list.yes')}
        </Button>
        <Button textOnly onClick={() => {
          setHandleItem(null)
          setShowConfirm(false)
        }}>
          {getTranslation('list.no')}
        </Button>
      </OverlayPanel>
    }
  </ListModuleWrapper>
}
export default RenderListOfData
