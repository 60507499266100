import { getToken } from '../../auth/auth-context-wrapper'


// Initial URL
// TODO Migrate API Url to confiugurable environment variable
export const API_URL = '/api/' // 'http://localhost:8000/api/';

// Handle response
const handleResponse = async data => {
  if (!data || !data.json) {
    return
  }

  try {
    if (data.status === 401) {
      return Promise.reject('unAuthenticated')
    }

    if (!data.ok) {
      return Promise.reject(data)
    }

    
    if (data.status >= 200 && data.status <= 299) {
      const contentType = data.headers.get("content-type");
      if (contentType && contentType.indexOf("application/json") !== -1) {
        return data.json()
      } else {
        return data
      }
    }

    return Promise.reject(data)
  } catch (error) {
    return Promise.reject(error)
  }
}

// Fetch / GET
export const fetchApi = async (url, header = {}) => {
  return fetch(`${API_URL}${url}`, {
    mode: 'cors', // no-cors, *cors, same-origin
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Token ${getToken()}`,
      ...header
    }
  }).then(data => handleResponse(data))
}

// POST
export const postApi = async (url, payload = {}, header = {}) => {
  const token = getToken()
  const defaultHeaders = {
    'Content-Type': 'application/json',
    ...header
  }

  if (token) {
    defaultHeaders.Authorization = `Token ${token}`
  }

  return fetch(`${API_URL}${url}`, {
    method: 'POST',
    mode: 'cors', // no-cors, *cors, same-origin
    headers: defaultHeaders,
    body: JSON.stringify(payload)
  }).then(data => handleResponse(data)).catch(e => console.log(e));
}

// PUT
export const putApi = async (url, id, payload = {}, header = {}) => {
  const token = getToken()
  const defaultHeaders = {
    'Content-Type': 'application/json',
    ...header
  }

  if (token) {
    defaultHeaders.Authorization = `Token ${token}`
  }

  const item_url = new URL(`${API_URL}${url}`, window.location.origin)

  if (item_url.pathname.includes && !item_url.pathname.includes(`${id}/`)) {
    item_url.pathname += `${id}/`
  }
  
  return fetch(item_url.href, {
    method: 'PUT',
    mode: 'cors', // no-cors, *cors, same-origin
    headers: defaultHeaders,
    body: JSON.stringify(payload)
  }).then(data => handleResponse(data)).catch((error) => Promise.reject(error))
}

// DELETE
export const deleteApi = async (url, id, payload = {}, header = {}) => {
  const token = getToken()
  const defaultHeaders = {
    'Content-Type': 'application/json',
    ...header
  }

  if (token) {
    defaultHeaders.Authorization = `Token ${token}`
  }

  const item_url = new URL(`${API_URL}${url}`, window.location.origin)
  if (item_url.pathname.includes && !item_url.pathname.includes(`${id}/`)) {
    item_url.pathname += `${id}/`
  }
  return fetch(item_url.href, {
    method: 'DELETE',
    mode: 'cors', // no-cors, *cors, same-origin
    headers: defaultHeaders,
    body: JSON.stringify(payload)
  }).then(data => handleResponse(data)).catch((error) => Promise.reject(error))
}
