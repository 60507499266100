import styled from 'styled-components'

import { H2 } from '../../core/ui/typo'

import { PageChildrenContainer } from '../../core/modules/page-title-slots'

// Styled components
export const OverlayWrapperStyling = styled.div`
  .inputDetailWrapper {
    display: inline-block;
    height: 95px;
    vertical-align: top;
    width: 50%;
    box-sizing: border-box;
    margin: 6px 0;

    &:nth-child(1), &:nth-child(3) {
      padding-right: 10px;
    }

    &:nth-child(2), &:nth-child(6) {
      padding-left: 10px;
    }
  }

  .checkbox-wrapper {
    cursor: pointer;

    > div {
      float: left;
      padding-right: 12px;
    }

    padding: 10px 0;
    clear: both;
    height: 40px;
  }

  .error {
    padding: 12px;
    background: red;
    color: #fff;
    margin-top: 12px;
  }
`

export const LicensesWrapper = styled.div`
  padding: 52px 0 22px;

  .message-wrapper {
    padding: 22px;
    background: rgba(0,0,0,0.1);
    text-align: center;
  }

  p.error {
    padding: 12px;
    background: red;
    color: #fff;
    margin-top: 12px;
    text-align: center;
  }
`

export const PageTitle = styled.div`
  position: relative;

  h2 {
    position: relative;
    top: -16px;
  }
`


// Child components
export const PageTitleWrapper = ({ title, children, style }) => {
  return (
    <PageTitle style={style}>
      <H2> {title} </H2>
      <PageChildrenContainer>{children}</PageChildrenContainer>
    </PageTitle>
  );
};