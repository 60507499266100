import styled from 'styled-components';
import React from 'react';

export const Label = styled.label`
  font-size: 16px;
  padding: 8px 0px;
  font-weight: 800;
`;

export const AccButton = styled.button`
  padding: 0;
  margin: 0;
  background: none;
  border: 0px solid #000;
  color: #fff;

  &:focus,
  &:hover,
  &:active {
    outline: 0;
    cursor: pointer;
  }
`;

// Button
export const Button = styled.button`
  height: 55px;
  line-height: 55px;
  width: 100%;
  border: 0px solid #fff;
  background: #fff;
  color: #222222;
  text-align: center;
  text-transform: uppercase;
  font-size: 16px;
  cursor: pointer;
  z-index: 999;
  transition: color, background ease 0.3s;
  pointer-events: auto;
  display: block;
  padding: 0 26px;
  border-radius: 6px;

  ${props =>
    props.primary
      ? `
        background: #c7a54a;
        color: #fff;
        font-size: 14px;
        text-transform: capitalize;
        height: 48px;
        line-height: 48px;
      `
      : ``}

  &:focus, &:hover, &:active {
    outline: 0;
    background: #1f1c3c;
    color: #fff;
    cursor: pointer;
  }

  ${props =>
    props.secondary
      ? `
        background: #1f1c3c;
        color: #fff;
        font-size: 14px;
        text-transform: capitalize;
        height: 48px;
        line-height: 48px;

        &:hover {
          background: #c7a54a;
        }
      `
      : ``}

  ${props => (props.textOnly ? 'margin: 12px 0 0 0; background: rgba(0,0,0,.7); color: #fff;' : '')}
`;

export const A = styled.a`
  height: 55px;
  line-height: 55px;
  width: auto;
  border: 0px solid #fff;
  background: #fff;
  color: #222222;
  text-align: center;
  text-transform: uppercase;
  font-size: 16px;
  cursor: pointer;
  z-index: 999;
  transition: color, background ease 0.3s;
  pointer-events: auto;
  display: block;
  padding: 0 26px;
  border-radius: 6px;
  margin: 0px 4px;
  font-weight: 500;

  ${props =>
    props.primary
      ? `
        background: #c7a54a;
        color: #fff;
        font-size: 14px;
        text-transform: capitalize;
        height: 48px;
        line-height: 48px;
      `
      : ``}

  &:focus, &:hover, &:active {
    outline: 0;
    background: #1f1c3c;
    color: #fff;
    cursor: pointer;
  }

  ${props =>
    props.secondary
      ? `
        background: #1f1c3c;
        color: #fff;
        font-size: 14px;
        text-transform: capitalize;
        height: 48px;
        line-height: 48px;

        &:hover {
          background: #c7a54a;
        }
      `
      : ``}

  ${props => (props.textOnly ? 'margin: 12px 0 0 0; background: rgba(0,0,0,.7); color: #fff;' : '')}
`;

// Input field
export const Field = styled.input`
  border: 0px solid #fff;
  width: 100%;
  height: 55px;
  line-height: 55px;

  padding: 0px 12px;
  margin: 12px 0;
  box-sizing: border-box;
  font-weight: 500;

  background: ${props => (props.primary ? '#fff' : 'rgba(255,255,255,0.2)')};
  color: ${props => (props.primary ? '#222222' : '#fff')};
  font-size: 17px;

  &::placeholder {
    color: ${props => (props.primary ? '#222222' : 'rgba(255,255,255,0.5)')};
    font-size: 17px;
  }

  &:focus,
  &:hover,
  &:active {
    outline: 0;
  }

  &:disabled {
    cursor: not-allowed;
    background: rgba(0, 0, 0, 0.15);
  }

  border-radius: 6px;
`;

export const FieldWithLabel = ({ isDisabled, value, handleChange, placeholder, label, id, type = 'text' }) => {
  return (
    <>
      <Label htmlFor={id}> {label}: </Label>
      <Field id={id} type={type} disabled={isDisabled} value={value} onChange={handleChange} placeholder={placeholder} />
    </>
  );
};

// Input text area.
export const TextField = styled.textarea`
  border: 0px solid #fff;
  width: 100%;
  height: 7em;

  padding: 16px 12px;
  margin: 12px 0;
  box-sizing: border-box;
  font-weight: 500;

  background: ${props => (props.primary ? '#fff' : 'rgba(255,255,255,0.2)')};
  color: ${props => (props.primary ? '#222222' : '#fff')};
  font-size: 17px;

  &::placeholder {
    color: ${props => (props.primary ? '#222222' : '#fff')};
    font-size: 17px;
  }

  &:focus,
  &:hover,
  &:active {
    outline: 0;
  }

  &:disabled {
    cursor: not-allowed;
    background: rgba(0, 0, 0, 0.15);
  }

  border-radius: 6px;
`;

// Input text area.
export const SelectField = styled.div`
  padding: 8px 22px;
  border-radius: 12px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  margin-right: 0;

  select {
    border: 0px solid #000;
    width: 100%;
  }
`;

export const FilterSelect = styled.div`
  > div label, div div {
    float: left;
  }

  >div label {
    margin: 0 10px;
  }
`

export const SelectArea = ({ label, value, defaultValue, onChange, children, style, ...props }) => (
  <FilterSelect>
    <div style={{
    position: 'relative'
  }}>
    <div style={style}>
      {label && <Label>{label}</Label>}
      <SelectField>
        <select value={value} defaultValue={defaultValue} onChange={onChange}>
          {children}
        </select>
      </SelectField>
    </div>
  </div>
  </FilterSelect>
);

export const TextFieldWithLabel = ({ isDisabled, value, handleChange, placeholder, label, id, type = 'textarea' }) => {
  return (
    <>
      <Label htmlFor={id}> {label}: </Label>
      <TextField id={id} disabled={isDisabled} value={value} onChange={handleChange} placeholder={placeholder} />
    </>
  );
};
