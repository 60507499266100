import React from "react"
import {useParams, withRouter} from "react-router-dom"
import Header from "../components/header"
import MaxWidth from "../core/modules/max-width-content"
import Footer from "../components/footer"
import LicenseDetails from "../components/license"
import PageTitle from "../core/modules/page-title"
import {useTranslations} from "../translations/useTranslations"
import useFetchLicense from "../apis/license"
import Devices from "../components/devicelicenses"
import LoadingContainer from "../core/modules/loading-container"

const ManageLicenseScreen = ({ history }) => {
  const { id: licenseId } = useParams()

  const { data, isLoading, isError, putRequest, fetchRequest } = useFetchLicense(licenseId)
  const [licenseData, setLicenseData] = React.useState({})
  const [internalLoading, setIsLoading] = React.useState(true)

  const {getTranslation} = useTranslations()

  React.useEffect(() => {
    setIsLoading(false)
    setLicenseData(data)
  }, [data])

  const updateUser = (key, value) => {
    const newState = {
      ...licenseData,
      [key]: value
    }
    setLicenseData(newState)
  }

  if (!licenseData.id || internalLoading || isLoading) {
    return null
  }

  return <>
    <Header />
    <MaxWidth>
      <PageTitle
        title={getTranslation('license.title')}
        buttonText={getTranslation('license.button')}
        handleClick={() => {
          putRequest(licenseData.id, licenseData).then(() => {
            history.push('/licenses')
          })
        }}
      />
      <LoadingContainer
        isLoading={(isLoading || internalLoading)}
        isError={isError}
      >
        <LicenseDetails
          licenseData={licenseData}
          secondary
          handleChange={updateUser}
        />
        <Devices
          fetchLicenseInformation={fetchRequest} />
      </LoadingContainer>
      <Footer />
    </MaxWidth>
  </>;
}

export default withRouter(ManageLicenseScreen)