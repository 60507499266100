import React from 'react'
import styled from 'styled-components'

// Core
import { PageTitle } from '../ui/typo'

// Module styling
const PageTitleWrapper = styled.div`
  position: relative;
  padding-top: 60px;
  padding-bottom: 10px;
  width: 100%;

  .buttonHolder {
    float: right;
    margin-top: -9px;
  }

  h1, h2, h3, h4, h5, h6 {
    width: 100%;
    display: inline-block;
  }
`

export const PageChildrenContainer = styled.div`
  position: absolute;
  right: 0px;
  top: 0px;

  a, button {
    float:left; 
    margin: 0 12px;
    height: 48px;
  }

  > div {
    float:left;
    margin-top:4px;
  }

  a:last-child, button:last-child {
    margin:0px;
  }
`

// Component
const PageTitleSlotsModule = ({ title, buttonText, handleClick, children, className }) => {
  return <PageTitleWrapper>
    <PageTitle style={{position: 'relative'}}> 
      {title}

      <PageChildrenContainer className={className}>
        {children}
      </PageChildrenContainer>   
    </PageTitle>
    
  </PageTitleWrapper>
}

export default PageTitleSlotsModule