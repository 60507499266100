import React from 'react'
import useFetchAppState from './utils/fetch-app-state'
import { useHistory } from "react-router-dom";

const useFetchLicenses = (options = {}) => {
  const { orderid,
     filter // For Testing
  } = options

  const url = getFetchLicensesEndpoint(orderid, filter);
  const [endpoint] = React.useState(url)
  const history = useHistory()
  const [key] = React.useState(url.includes('order=') ? `licenses-orderid-${orderid}` : 'licenses')
  const payload = useFetchAppState(key, endpoint)
  if (payload.unAuthenticated) {
    history.push("/logout")
  }

  return payload
}

export const getFetchLicensesEndpoint = (orderid, filter, endpoint = '') => {
  
  // const filter = 'expired' // For testing;
  // Newly intrduced valid / expired filter
  const hasAnOrderId = (orderid !== undefined || orderid !== null || orderid !== 'undefined')
  const hasFilter = (filter !== undefined || filter !== null || filter !== 'undefined')

  const hasParams = hasAnOrderId || hasFilter;

  let url = 'v1/licenses/'

  if (endpoint)
    url = `${url}${endpoint}/`

  if (hasParams) url = `${url}?`
  if (orderid) url = `${url}order=${orderid}`
  if (filter) {
    const normalizedFilter = `${filter.toLowerCase()}=true`;
    url = `${url}${hasAnOrderId ? `&${normalizedFilter}` : `${normalizedFilter}`}`
  }

  return url;
}
export default useFetchLicenses
